import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ReactMarkdown from 'react-markdown'
import './onlinePayment.css'
import {
    MDBBtn,
    MDBIcon,
  } from 'mdbreact'

const OnlinePayment = () => {
  const data = useStaticQuery(graphql`
    {
    allStrapiOnlinePaymentPages {
      nodes {
        OnlySupportingOrgPrice
        NewMembersOnly
        NeworRenewingMember
        NewMembersOnlyText
        PleaseNote
        SupportingOrganization
        categories
        date
        NewMembersOnlyPrice {
        NumberOnly
        TextOnly
        }
        NewOrRenewingMember {
        NumberOnly
        textOnly
        }
      }
    }
  }
  `)
    
    var payment = data.allStrapiOnlinePaymentPages.nodes;
    
    let arr = payment[0].NewMembersOnlyPrice;
    let NewMembersOnlyPrice = arr.map(function(element){
        return <option value={element.NumberOnly}>{element.TextOnly}</option>
    });
    let arr1 = payment[0].NewOrRenewingMember;
    let NewOrRenewingMember= arr1.map(function(element){
        return <option value={element.NumberOnly}>{element.textOnly}</option>
    });

    
    function NewOrRenew(){
        var SelectedPrice = document.getElementById("NewOrRenewingMemberSelectedPrice").value;
        console.log(SelectedPrice)
        sessionStorage.setItem("selectedPrice",SelectedPrice);
        var MembershipCategory = payment[0].NeworRenewingMember;
        sessionStorage.setItem("MembershipCategory",MembershipCategory);
        window.location.pathname="/paymentDetails";
        

    }

    function NewOnly(){
        var SelectedPrice = document.getElementById("NewMemberOnlySelectedPrice").value;
        sessionStorage.setItem("selectedPrice",SelectedPrice);
        var MembershipCategory = payment[0].NewMembersOnlyText;
        sessionStorage.setItem("MembershipCategory",MembershipCategory);
        window.location.pathname="/paymentDetails";
    }

    function SupportOrg(){
        var SelectedPrice = document.getElementById("supportingOrgPrice").value;
        sessionStorage.setItem("selectedPrice",SelectedPrice);
        var MembershipCategory = payment[0].SupportingOrganization;
        sessionStorage.setItem("MembershipCategory",MembershipCategory);
        window.location.pathname="/paymentDetails";

    }
    

  return (
    <div className="card onlinecard" style ={{backgroundColor: "rgb(249, 249, 255)" }}>
    <div className="container">
    <div className="pleasenote">
    <p><ReactMarkdown source={payment[0].PleaseNote} /></p>
    </div>
    <hr/>
    <div className="categories">
    <p><ReactMarkdown source={payment[0].categories} /></p>
    </div>
    <br />
    <div class="row">
        <div class="column">
            <div className="NewOrRenewingMember">
            <h3 class="categoryTitle" style={{marginBottom:"30px"}}>{payment[0].NeworRenewingMember}</h3>
            <label  htmlFor="NeworRenewingMember">{payment[0].NeworRenewingMember}</label> 
            <select style={{marginBottom:"25px"}} class="form-control"  id="NewOrRenewingMemberSelectedPrice">{NewOrRenewingMember}</select>
            <div style={{textAlign: "center"}}>
            <button type="submit" id="NewOrRenewingMemberPrice" class="btn btn-success btn1" value="" onClick={NewOrRenew}
            style={{backgroundColor:"rgb(0, 128, 0)",
            borderRadius:"7px",
            fontSize:"16px",
            width:"45%"}}>PAY NOW</button>
            </div>
            </div>
        </div>
        
        <div class="column">
        <div className="supportingOrg">
            <h3 class="categoryTitle" style={{marginBottom:"55px"}}>{payment[0].SupportingOrganization}</h3>  
            <div style={{textAlign: "center"}}>
            <h5 style={{backgroundColor:"white", paddingTop:"7px",paddingBottom:"7px", border:"0.1px solid #D9D9D9",borderRadius:"5px",marginBottom:"25px"}}>$ {payment[0].OnlySupportingOrgPrice} USD</h5>
            <button type="submit" id="supportingOrgPrice" class="btn btn-success btn2" value={payment[0].OnlySupportingOrgPrice} onClick={SupportOrg}
            style={{backgroundColor:"rgb(0, 128, 0)",
            borderRadius:"7px",
            fontSize:"16px",
            width:"48%"}}>PAY NOW</button>
            </div>
        </div>
        </div>
    </div>

    <hr/>

    <div className="NewMembersOnly">
    <p><ReactMarkdown source={payment[0].NewMembersOnly} /></p>
    </div><br />
            
        <div style={{justifyContent:"center",display:"flex"}}>
            <div className="NewMembersOnly">
                <h3 style={{marginBottom:"30px"}}>{payment[0].NewMembersOnlyText}</h3>
                <label  htmlFor="NeworRenewingMember">Choose Category:</label> 
                <select style={{marginBottom:"25px"}} class="form-control"  id="NewMemberOnlySelectedPrice">{NewMembersOnlyPrice}</select>
                <div style={{textAlign: "center"}}>
                <button type="submit" id="NewMemberOnlyPrice" class="btn btn-success " value="" onClick={NewOnly}
                style={{backgroundColor:"rgb(0, 128, 0)",
                borderRadius:"7px",
                fontSize:"16px",
                width:"65%"}}>PAY NOW</button>
                </div>
            </div>
        </div>

    <hr />
    </div>
    </div>
)
}

export default OnlinePayment
