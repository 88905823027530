import React from "react";
import Layout from "../components/layout";
import OnlinePayment from "../components/OnlinePayment";
import '../components/onlinePayment.css'


const MembershipCategory =() =>(
    <Layout>
        <OnlinePayment />
    </Layout>
)

export default MembershipCategory;
